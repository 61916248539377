import { FooterContainer, FooterContent } from './footer.styles';
import { FOOTER_ITEMS } from 'shared/constants/nav-items';
import FooterItems from './footer-items';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterItems items={FOOTER_ITEMS}></FooterItems>
      </FooterContent>
    </FooterContainer>
  );
}
