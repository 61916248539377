import Link from 'next/link';
import { NavigationItem } from 'shared/constants/nav-items';
import { handleExternallLink } from 'shared/constants/utilities';
import { FooterItemContainer, FooterItemTitle } from './footer-item.styles';

type FooterItemProps = {
  item: NavigationItem;
};

export default function FooterItem({ item: { name, children } }: FooterItemProps) {
  return (
    <FooterItemContainer>
      <FooterItemTitle>{name}</FooterItemTitle>
      {/* Footer routes (e.g. Overview, Glaci Apps, Core Extensions) */}
      {children?.map((child: NavigationItem, idx) => (
        <Link href="" key={idx} onClick={(e) => handleExternallLink(e, child?.link || '')}>
          {child.name}
        </Link>
      ))}
    </FooterItemContainer>
  );
}
