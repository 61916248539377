import Link from 'next/link';
import { useRouter } from 'next/router';

import { useState } from 'react';
import { Flipper } from 'react-flip-toolkit';

import { HeaderActions, HeaderContainer, HeaderContent, HeaderNavigationContainer, HeaderTabs } from './header.styles';
import HeaderPopup from './header-popoup';

import { matchInArray } from 'shared/constants/utilities';
import { HeaderTab } from 'shared/constants/nav-items';
import { PrimaryLink } from 'shared/styles/components';
import { LIBUM_EAP_QUESTIONNAIRE, SECONDARY_THEME_ROUTES } from 'shared/constants/globals';

import LIBUM_COLOR from 'public/logos/libum_color.svg';
import LIBUM_GHOST from 'public/logos/libum_ghost.svg';
import HOVER_ARROW from 'public/icons/hover_arrow.svg';

import HeaderDropdown from './header-dropdown';

import CompanyDropdown from './header-dropdown/header-dropdown-contents/company-dropdown';
import GlaciDropdown from './header-dropdown/header-dropdown-contents/glaci-dropdown';
import NexumAPIDropdown from './header-dropdown/header-dropdown-contents/nexum-dropdown';
import ResourcesDropdown from './header-dropdown/header-dropdown-contents/resources-dropdown';
import HeaderDropdownContainer from './header-dropdown/header-dropdown-container';

type HeaderProps = {
  className?: string;
};

const dropdownConfig = [
  { title: HeaderTab.GLACI, dropdown: GlaciDropdown },
  { title: HeaderTab.NEXUM_API, dropdown: NexumAPIDropdown },
  { title: HeaderTab.COMPANY, dropdown: CompanyDropdown },
  { title: HeaderTab.RESOURCES, dropdown: ResourcesDropdown },
];

export default function Header({ className }: HeaderProps) {
  const duration = 300;
  let animatingOutTimeout: NodeJS.Timeout | undefined;
  let CurrentDropdown: () => JSX.Element;
  let PreviousDropdown: () => JSX.Element;
  let direction: 'left' | 'right' | undefined;

  const router = useRouter();
  const theme = matchInArray(router.pathname, SECONDARY_THEME_ROUTES) ? 'secondary' : 'primary';
  const is404 = router.pathname === '/404';

  const [state, setState] = useState({
    activeIndices: [] as any[],
    animatingOut: false,
  });

  const resetState = (i?: number): void => {
    setState({
      activeIndices: typeof i === 'number' ? [i] : [],
      animatingOut: false,
    });
    animatingOutTimeout = undefined;
  };

  const toggleDropdown = (i: number) => {
    return (e: any) => {
      if (e.target.matches('[class*="HeaderDropdownRoot"]') || e.target.classList.contains('is-dropdown')) {
        onMouseEnter(i, true);
      } else if (!e.target.matches('[class*="HeaderDropdown"]')) {
        onMouseEnter(i, true);
      }
    };
  };

  const onMouseEnter = (i: number | undefined, toggle = false): void => {
    if (toggle) resetState(undefined);

    if (animatingOutTimeout) {
      clearTimeout(animatingOutTimeout);
      resetState(i);
      return;
    }
    if (state.activeIndices[state.activeIndices.length - 1] === i) return;

    setState({
      activeIndices: [...state.activeIndices, i],
      animatingOut: false,
    });
  };

  const onMouseLeave = (): void => {
    setState({
      ...state,
      animatingOut: true,
    });
    animatingOutTimeout = setTimeout(() => resetState(), 0);
  };

  const currentIndex = state.activeIndices[state.activeIndices.length - 1];

  const previousIndex = state.activeIndices.length > 1 && state.activeIndices[state.activeIndices.length - 2];

  if (typeof currentIndex === 'number') CurrentDropdown = dropdownConfig[currentIndex].dropdown;
  if (typeof previousIndex === 'number') {
    PreviousDropdown = dropdownConfig[previousIndex].dropdown;
    direction = currentIndex > previousIndex ? 'left' : 'right';
  }

  return (
    <nav className={className}>
      <HeaderContainer>
        <HeaderContent>
          <HeaderNavigationContainer>
            {/* Header logo */}
            <Link href="/" passHref legacyBehavior>
              <a style={{ display: 'flex' }}>
                {theme === 'primary' || is404 ? <LIBUM_COLOR height={29} /> : <LIBUM_GHOST height={29} />}
              </a>
            </Link>
            {/* Header tabs */}
            <Flipper flipKey={currentIndex} spring={'noWobble'}>
              <HeaderTabs onMouseLeave={onMouseLeave} type={theme}>
                {/* Dropdown tabs */}
                {dropdownConfig.map((item, idx) => (
                  <HeaderDropdown
                    key={item.title}
                    title={item.title}
                    idx={idx}
                    onMouseEnter={onMouseEnter}
                    toggleDropdown={toggleDropdown}
                  >
                    {idx === currentIndex && (
                      <HeaderDropdownContainer
                        duration={duration}
                        direction={direction}
                        animatingOut={state.animatingOut}
                      >
                        <CurrentDropdown />
                        {PreviousDropdown && <PreviousDropdown />}
                      </HeaderDropdownContainer>
                    )}
                  </HeaderDropdown>
                ))}
                {/* Blog tab */}
                <Link href="/blog" onMouseEnter={() => onMouseEnter(undefined)}>
                  Blog
                </Link>
                {/* Pricing tab */}
                <Link href="/pricing" onMouseEnter={() => onMouseEnter(undefined)}>
                  Pricing
                </Link>
              </HeaderTabs>
            </Flipper>
            {/* Header actions */}
            <HeaderActions>
              <Link href={LIBUM_EAP_QUESTIONNAIRE} passHref legacyBehavior>
                <PrimaryLink className="root-link" target="_blank">
                  Early Access
                  <HOVER_ARROW />
                </PrimaryLink>
              </Link>
              {/* Header mobile popup */}
              <HeaderPopup is404={is404} theme={theme} />
            </HeaderActions>
          </HeaderNavigationContainer>
        </HeaderContent>
      </HeaderContainer>
    </nav>
  );
}
