import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: ${(props) => props.theme.colors.textPrimary};

  nav.primary {
    background-color: ${(props) => props.theme.colors.bgPrimary};
  }
  nav.secondary {
    background-color: ${(props) => props.theme.colors.bgColor};
  }
  nav.gray {
    background-color: ${(props) => props.theme.colors.bgGray};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
`;
