import { HeaderDropdownLink, HeaderDropdownSection } from '../header-dropdown-contents.styles';
import { HeaderDropdownResourcesContainer } from './resources-dropdown.styles';

import Book from 'public/icons/eva/book.svg';
import MessageCircle from 'public/icons/eva/message-circle.svg';
import Link from 'next/link';
import { LIBUM_FEEDBACK } from 'shared/constants/globals';

export default function ResourcesDropdown() {
  return (
    <HeaderDropdownResourcesContainer>
      <HeaderDropdownSection data-first-dropdown-section>
        <ul>
          <HeaderDropdownLink>
            <Link href="/docs">
              <Book />
              Docs
            </Link>
          </HeaderDropdownLink>
        </ul>
      </HeaderDropdownSection>
      <HeaderDropdownSection>
        <ul>
          <HeaderDropdownLink>
            <Link href={LIBUM_FEEDBACK} target="_blank">
              <MessageCircle />
              Feedback
            </Link>
          </HeaderDropdownLink>
        </ul>
      </HeaderDropdownSection>
    </HeaderDropdownResourcesContainer>
  );
}
