import styled from 'styled-components';

export const HeaderDropdownGlaciContainer = styled.div`
  width: 600px;
`;

export const HeaderDropdownGlaciFeaturedList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const HeaderDropdownGlaciFeature = styled.li`
  margin-bottom: 32px;
  min-height: 56px;
  cursor: pointer;

  a {
    display: flex;
    padding: 0;

    & > img {
      margin-right: 4px;
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 38%;
    }
  }

  &:hover h2 {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const HeaderDropdownGlaciFeatureDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  padding: 2px;

  h1 {
    display: flex;
    align-items: center;
    margin: 0;
    color: ${(props) => props.theme.colors.textPrimary};
    font-family: ${(props) => props.theme.fontFamily.display};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;

    & > svg {
      fill: transparent !important;
    }
  }

  h2 {
    display: block;
    margin-top: 2px;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.gray600};
    font-family: ${(props) => props.theme.fontFamily.display};
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
    text-align: left;
  }
`;
