import styled from 'styled-components';
import { devices } from 'shared/styles/components';

export const HeaderContainer = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 20px;
  margin: 0 auto;
  padding: 0 var(--page-gutter-padding);
  max-width: var(--page-max-width);
  width: 100%;

  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  grid-column: span var(--page-grid-cols);
`;

export const HeaderNavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
`;

export const HeaderTabs = styled.div<{ type: string }>`
  display: none;
  flex: 1 1 auto;
  justify-content: center;
  color: ${(props) => (props.type === 'primary' ? props.theme.colors.textPrimary : props.theme.colors.textSecondary)};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  cursor: default;

  & > a {
    padding: 20px;
    user-select: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  @media ${devices.laptopSMin} {
    display: flex;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;
