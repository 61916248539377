import { ParsedUrlQueryInput } from "querystring";
import { GLACI_FEATURES } from "../data/glaci";
import { LIBUM_API_NEXUM, LIBUM_FEEDBACK, LIBUM_STATUS } from "./globals";

export enum HeaderTab {
  GLACI = 'Glaci',
  NEXUM_API = 'NexumAPI',
  COMPANY = 'Company',
  RESOURCES = 'Resources',
  BLOG = 'Blog',
  PRICING = 'Pricing',
}

export enum FooterTab {
  GLACI = 'Glaci',
  NEXUM_API = 'NexumAPI',
  COMPANY = 'Company',
  RESOURCES = 'Resources',
}

export type NavigationItem = {
  id: string;
  name: string | HeaderTab;
  description?: string;
  icon?: NavigationIcon;
  link?: string;
  params?: ParsedUrlQueryInput;
  children?: NavigationItem[];
}

export interface NavigationIcon {
  name: string;
  type: string;
}

export type NavigationOption = {
  name: string;
  description?: string;
  icon?: NavigationIcon;
  link?: string;
  params?: ParsedUrlQueryInput;
  children?: NavigationOption[];
}

const FOOTER_OPTIONS: NavigationOption[] = [{
  name: FooterTab.GLACI,
  children: [
    { name: 'Overview', link: '/glaci' },
    { name: 'Apps & Exts', link: '/glaci#explore' },
    { name: 'Pricing', link: '/glaci#plan' },
  ],
}, {
  name: FooterTab.NEXUM_API,
  children: [
    { name: 'Overview',  link: '/nexum' },
    { name: 'Features',  link: '/nexum#features' },
    { name: 'API Reference',  link: LIBUM_API_NEXUM },
    { name: 'API Status',  link: LIBUM_STATUS },
    { name: 'Offerings',  link: '/nexum#plan'},
  ],
}, {
  name: FooterTab.COMPANY,
  children: [
    { name: 'Overview',  link: '/company' },
    { name: 'About Us',  link: '/company#about-us' },
    { name: 'Our Story',  link: '/company#our-story' },
    { name: 'Jobs',  link: '/jobs' },
    { name: 'Leadership',  link: '/company#leadership' },
    { name: 'Legal',  link: '/legal' },
  ],
}, {
  name: FooterTab.RESOURCES,
  children: [
    { name: 'Blog',  link: '/blog' },
    { name: 'Company Information',  link: '/company-information' },
    { name: 'Documentation',  link: '/docs' },
    { name: 'Feedback',  link: LIBUM_FEEDBACK },
  ],
}];

const HEADER_OPTIONS: NavigationOption[] = [{
  name: HeaderTab.GLACI,
  children: [{
    name: 'Glaci Apps',
    children: [
      ... getFeaturedOptions(['analytics-live', 'microsoft-365', 'password-manager'])
    ],
  }, {
    name: 'Core Extensions',
    children: [
      ... getFeaturedOptions(['dynamic-overlay', 'ctr-worksheets', 'cu-lambda']),
    ],
  }]
}, {
  name: HeaderTab.NEXUM_API,
  children: [],
}, {
  name: HeaderTab.COMPANY,
  children: [
    { name: 'Overview', icon: { name: 'compass', type: 'eva' },  link: '/company' },
    { name: 'About Us', icon: { name: 'home', type: 'eva' },  link: '/company#about-us' },
    { name: 'Our Story', icon: { name: 'book-open', type: 'eva' },  link: '/company#our-story' },
    { name: 'Jobs', icon: { name: 'briefcase', type: 'eva' },  link: '/jobs' },
    { name: 'Leadership', icon: { name: 'award', type: 'eva' },  link: '/company#leadership' },
    { name: 'Legal', icon: { name: 'shield', type: 'eva' },  link: '/legal' },
  ],
}, {
  name: HeaderTab.RESOURCES,
  children: [
    { name: 'Documentation', icon: { name: 'book', type: 'eva' },  link: '/docs' },
    { name: 'Feedback', icon: { name: 'message-circle', type: 'eva' },  link: LIBUM_FEEDBACK },
  ],
}, {
  name: HeaderTab.BLOG,
  link: '/blog',
}, {
  name: HeaderTab.PRICING,
  link: '/pricing',
}];

/**
 * Gets the featured Glaci Apps or Core Extensions to include in navigation
 * @param ids The Glaci feature ids
 * @returns The featured Glaci Apps or Core Extensions
 */
function getFeaturedOptions(ids: string[]): NavigationOption[] {
  return GLACI_FEATURES.filter(feature => ids.includes(feature.id)).map(feature => ({
    name: feature.name,
    description: feature.description,
    link: `/glaci/${feature.id}`,
  }));
}

const HEADER_POPUP_OPTIONS: NavigationOption[] = [{
  name: HeaderTab.GLACI,
  children: [
    { name: 'Overview', icon: { name: 'compass', type: 'eva' },  link: '/glaci' },
    { name: 'Apps & Exts', icon: { name: 'keypad', type: 'eva' },  link: '/glaci#explore' },
    { name: 'Pricing', icon: { name: 'pricetag', type: 'eva' },  link: '/glaci#plan' },
  ],
}, {
  name: 'Glaci Features',
  children: [
    ...GLACI_FEATURES.map((ae) => ({
      name: ae.name,
      icon: {
        name: ae.id,
        type: 'glaci',
      },
      link: `/glaci/${ae.id}`,
    })),
  ]
},  {
  name: HeaderTab.NEXUM_API,
  children: [
    { name: 'Overview', icon: { name: 'compass', type: 'eva' },  link: '/nexum' },
    { name: 'API Reference', icon: { name: 'book', type: 'eva' },  link: LIBUM_API_NEXUM },
    { name: 'API Status', icon: { name: 'activity', type: 'eva' },  link: LIBUM_STATUS },
    { name: 'Offerings', icon: { name: 'pricetag', type: 'eva' },  link: '/nexum#plan' },
  ],
}, {
  name: HeaderTab.COMPANY,
  children: [
    { name: 'Overview', icon: { name: 'compass', type: 'eva' },  link: '/company' },
    { name: 'About Us', icon: { name: 'home', type: 'eva' },  link: '/company#about-us' },
    { name: 'Our Story', icon: { name: 'book-open', type: 'eva' },  link: '/company#our-story' },
    { name: 'Jobs', icon: { name: 'briefcase', type: 'eva' },  link: '/jobs' },
    { name: 'Leadership', icon: { name: 'award', type: 'eva' },  link: '/company#leadership' },
    { name: 'Legal', icon: { name: 'shield', type: 'eva' },  link: '/legal' },
  ],
}, {
  name: HeaderTab.RESOURCES,
  children: [
    { name: 'Documentation', icon: { name: 'book', type: 'eva' },  link: '/docs' },
    { name: 'Feedback', icon: { name: 'message-circle', type: 'eva' },  link: LIBUM_FEEDBACK },
  ],
}];

/**
 * Assigns unique ids for navigation items
 * @param options The navigation options
 * @param depth The navigation depth
 * @returns The navigation items
 */
function createNavigationItems(options: NavigationOption[], depth = 0): NavigationItem[] {
  return options.map((option, idx) => ({
    ...option,
    id: depth === 0 ? idx.toString() : `${depth}.${idx}`,
    children: option.children ? createNavigationItems(option.children, depth + 1) : undefined,
  }));
}

export const HEADER_ITEMS: NavigationItem[] = createNavigationItems(HEADER_OPTIONS);
export const HEADER_POPUP_ITEMS: NavigationItem[] = createNavigationItems(HEADER_POPUP_OPTIONS);
export const FOOTER_ITEMS: NavigationItem[] = createNavigationItems(FOOTER_OPTIONS);
