import styled from 'styled-components';
import { PrimaryLink, devices } from 'shared/styles/components';

export const HeaderPopupContainer = styled.div`
  z-index: 10;

  @media ${devices.laptopSMin} {
    display: none;
  }
`;

export const HeaderPopupMenu = styled.a`
  display: flex;
  margin-left: 8px;

  svg.primary {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
  svg.secondary {
    fill: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const HeaderPopupClose = styled.a`
  position: absolute;
  top: 9px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;

  @media ${devices.mobileMax} {
    top: 9px;
    right: 9px;
  }
`;

export const HeaderPopupContentContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 10px;
  top: 5px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  perspective: 2000px;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export const HeaderPopupContent = styled.div`
  position: relative;
  flex-grow: 1;
  font-size: 17px;
  line-height: 40px;
  white-space: nowrap;
  background: ${(props) => props.theme.colors.bgPrimary};
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transform: rotate3d(1, 1, 0, -15deg);
  transform-origin: 100% 0;
  opacity: 0;
  will-change: transform, opacity;
  transition-property: opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.25s;

  &.active {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const HeaderPopupSection = styled.div`
  padding: 24px 24px 16px;

  &:not(.last) {
    border-bottom: 1px dashed ${(props) => props.theme.colors.gray200};
  }
`;

export const HeaderPopupSectionTitle = styled.h4`
  margin-top: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.textQuaternary};
  font-family: ${({ theme }) => theme.fontFamily.headline}};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const HeaderPopupSectionLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 8px;

  a {
    display: flex;
    align-items: center;
    grid-column: span 2;
    font-size: 14px;
    font-weight: 700;
  }

  a svg {
    margin-right: 8px;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;

    &.generic {
      fill: ${(props) => props.theme.colors.gray400};
      width: 20px;
      height: 20px;
    }
  }
`;

export const HeaderPopupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  height: 56px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.gray100};
`;

export const HeaderPopupFooterLinkDefault = styled.a`
  padding: 0 16px;
  color: ${(props) => props.theme.colors.textQuaternary};
  font-size: 15px;
  font-weight: 700;
  line-height: 38px;
`;

export const HeaderPopupFooterLinkPrimary = styled(PrimaryLink)`
  font-weight: 600;
  color: ${(props) => props.theme.colors.textSecondary};
  background-color: ${(props) => props.theme.colors.textQuaternary};
`;
