import React, { Children, forwardRef, useEffect, useRef } from 'react';
import { Flipped } from 'react-flip-toolkit';

import {
  HeaderDropdownArrow,
  HeaderDropdownBackgroundAlternate,
  HeaderDropdownBackgroundPrimary,
  HeaderDropdownFadeContainer,
  HeaderDropdownInverted,
  HeaderDropdownRoot,
} from './header-dropdown-container.styles';

type HeaderDropdownContainerProps = {
  children: React.ReactNode;
  animatingOut: boolean;
  direction: 'left' | 'right' | undefined;
  duration: number;
};

const HeaderDropdownFadeContents = forwardRef<HTMLElement, HeaderDropdownContainerProps>(
  ({ animatingOut, children, direction, duration }: HeaderDropdownContainerProps, ref: any): JSX.Element => {
    return (
      <HeaderDropdownFadeContainer
        aria-hidden={animatingOut}
        animatingOut={animatingOut}
        direction={direction}
        duration={duration}
        ref={ref}
      >
        {children}
      </HeaderDropdownFadeContainer>
    );
  },
);

HeaderDropdownFadeContents.displayName = 'HeaderDropdownFadeContents';

export default function HeaderDropdownContainer({
  children,
  animatingOut,
  direction,
  duration,
}: HeaderDropdownContainerProps) {
  let alternateBackgroundEl: HTMLElement | null;
  const previousDropdownEl = useRef<HTMLElement>(null);
  const currentDropdownEl = useRef<HTMLElement>(null);
  const [currentDropdown, previousDropdown] = Children.toArray(children);

  const getFirstDropdownSectionHeight = (el: HTMLElement | null): number => {
    if (!el || !el.querySelector || !el.querySelector('*[data-first-dropdown-section]')) return 0;
    return el.querySelector<HTMLElement>('*[data-first-dropdown-section]')!.offsetHeight;
  };

  type HeaderDropdownBackgroundAlternate = {
    alternateBackground: HTMLElement | null;
    previousDropdown: HTMLElement | null;
    currentDropdown: HTMLElement | null;
    duration: number;
  };

  const updateAlternateBackground = ({
    alternateBackground,
    previousDropdown,
    currentDropdown,
  }: HeaderDropdownBackgroundAlternate): void => {
    const previousHeight = getFirstDropdownSectionHeight(previousDropdown);
    const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

    const immediateSetTranslateY = (el: HTMLElement | null, translateY: number): void => {
      if (!el || !el.querySelector) return;

      el.style.transform = `translateY(${translateY}px)`;
      el.style.transition = 'transform 0ms';
      requestAnimationFrame(() => (el.style.transitionDuration = ''));
    };

    if (previousHeight) {
      immediateSetTranslateY(alternateBackground, previousHeight);
      requestAnimationFrame(() => {
        if (!alternateBackground) return;
        alternateBackground.style.transform = `translateY(${currentHeight}px)`;
      });
    } else {
      immediateSetTranslateY(alternateBackground, currentHeight);
    }
  };

  useEffect(() => {
    updateAlternateBackground({
      alternateBackground: alternateBackgroundEl,
      previousDropdown: previousDropdownEl.current,
      currentDropdown: currentDropdownEl.current,
      duration,
    });
  });

  return (
    <HeaderDropdownRoot direction={direction} animatingOut={animatingOut} duration={duration}>
      <Flipped flipId="dropdown-arrow">
        <HeaderDropdownArrow />
      </Flipped>
      <Flipped flipId="dropdown">
        <HeaderDropdownBackgroundPrimary>
          <Flipped inverseFlipId="dropdown">
            <HeaderDropdownInverted>
              <HeaderDropdownBackgroundAlternate ref={(el) => (alternateBackgroundEl = el)} duration={duration} />
              <HeaderDropdownFadeContents
                animatingOut={false}
                direction={direction}
                duration={duration}
                ref={currentDropdownEl}
              >
                {currentDropdown}
              </HeaderDropdownFadeContents>
            </HeaderDropdownInverted>
          </Flipped>

          <Flipped inverseFlipId="dropdown" scale>
            {/* @ts-ignore */}
            <HeaderDropdownInverted absolute>
              {previousDropdown && (
                <HeaderDropdownFadeContents
                  animatingOut
                  direction={direction}
                  duration={duration}
                  ref={previousDropdownEl}
                >
                  {previousDropdown}
                </HeaderDropdownFadeContents>
              )}
            </HeaderDropdownInverted>
          </Flipped>
        </HeaderDropdownBackgroundPrimary>
      </Flipped>
    </HeaderDropdownRoot>
  );
}
