/**
 * Class for dropdown structure
 */
export class HeaderStructure {
  public isRetina: boolean;
  public isMobileViewport: boolean;
  public mobileViewportWidth: number;
  public touch: any;

  constructor() {
    this.isRetina = window.devicePixelRatio > 1.3;
    this.mobileViewportWidth = 670;
    this.isMobileViewport = window.innerWidth < this.mobileViewportWidth;
    this.touch = {
      isSupported: 'ontouchstart' in window || navigator.maxTouchPoints,
      isDragging: !1,
    };
  }

  /**
   * Queries for elemnets with className selector
   * @param className The class name to query
   * @param elem The element to reference
   */
  public queryArray(className: string, elem: HTMLElement): any {
    return elem || (elem = document.body), Array.prototype.slice.call(elem.querySelectorAll(className));
  }
}
