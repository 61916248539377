import styled from 'styled-components';

export const FooterContainer = styled.footer`
  color: ${(props) => props.theme.colors.textSecondary};
  background-color: ${(props) => props.theme.colors.bgSecondary};
`;

export const FooterContent = styled.div`
  margin: 0 auto;
  padding: 80px var(--page-gutter-padding) 64px;
  max-width: var(--page-max-width);
`;
