import { HeaderDropdownLink, HeaderDropdownSection } from '../header-dropdown-contents.styles';
import { HeaderDropdownCompanyContainer } from './company-dropdown.styles';

import Compass from 'public/icons/eva/compass.svg';
import Home from 'public/icons/eva/home.svg';
import BookOpen from 'public/icons/eva/book-open.svg';
import Award from 'public/icons/eva/award.svg';
import Link from 'next/link';
import BriefCase from 'public/icons/eva/briefcase.svg';
import Shield from 'public/icons/eva/shield.svg';

export default function CompanyDropdown() {
  return (
    <HeaderDropdownCompanyContainer>
      <HeaderDropdownSection data-first-dropdown-section>
        <ul>
          <HeaderDropdownLink>
            <Link href="/company">
              <Compass />
              Overview
            </Link>
          </HeaderDropdownLink>
          <HeaderDropdownLink>
            <Link href="/company#about-us">
              <Home />
              About Us
            </Link>
          </HeaderDropdownLink>
          <HeaderDropdownLink>
            <Link href="/company#our-story">
              <BookOpen />
              Our Story
            </Link>
          </HeaderDropdownLink>
          <HeaderDropdownLink>
            <Link href="/jobs">
              <BriefCase />
              Jobs
            </Link>
          </HeaderDropdownLink>
        </ul>
      </HeaderDropdownSection>
      <HeaderDropdownSection>
        <ul>
          <HeaderDropdownLink>
            <Link href="/company#leadership">
              <Award />
              Leadership
            </Link>
          </HeaderDropdownLink>
          <HeaderDropdownLink>
            <Link href="/legal">
              <Shield />
              Legal
            </Link>
          </HeaderDropdownLink>
        </ul>
      </HeaderDropdownSection>
    </HeaderDropdownCompanyContainer>
  );
}
