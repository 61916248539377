import useDynamicSVGImport, { UseDynamicSVGImportOptions } from "shared/hooks/useDynamicSVGImport";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string;
  type: string;
  onCompleted?: UseDynamicSVGImportOptions["onCompleted"];
  onError?: UseDynamicSVGImportOptions["onError"];
}

export default function Icon ({
  name,
  type,
  onCompleted,
  onError,
  ...rest
}: IconProps): React.ReactElement | null {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, type, {
    onCompleted,
    onError
  });
  if (error) {
    console.log(error);
  }
  if (loading) {
    return <>Loading...</>
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />;
  }
  return null;
};
