import { AccordionItem } from 'shared/components/accordion/accordion.types';
import { PlanProps } from 'shared/components/plan/plan';
import { PlanFeature } from 'shared/components/plan/plan.types';
import { LIBUM_EAP_QUESTIONNAIRE, LIBUM_FEEDBACK, LIBUM_SCHEDULING_DEMO_DYLAN, SALES_MAILTO, SYMCENTRAL_2023_CONFERENCE_URL } from 'shared/constants/globals';
import { PlanOfferings } from 'shared/constants/plans';
import { createItems } from 'shared/constants/utilities';

/* FAQs */

export enum GlaciFAQGroup {
  GENERAL = 'General',
  PRICING_AND_AVAILABILITY = 'Pricing and Availability',
  OTHER = 'Other',
}

const GENERAL: AccordionItem[] = [{
  title: 'What is Glaci?',
  description: 'Glaci is a centralized platform designed to improve operational efficiencies, streamline workflows, and modernize your credit union tech stack.',
}, {
  title: 'What does Glaci do?',
  description: `Glaci augments the employee user experience (UX) as they service members, perform internal tasks, and interact with the Symitar core platform. It does this by leveraging new integration technologies provided on a rich user interface (UI) that's incredibly stable, yet fast`,
}, {
  title: 'How does Glaci work with Symitar?',
  description: 'We have developed a diverse set of integration methodologies (e.g. SymXchange™ via <a href="/nexum">NexumAPI</a>, PowerOn hooks, native app bindings, etc.) in order to make Glaci seem immersed within the Symitar GUI yet independent enough to not require the Symitar GUI to do various core related tasks.',
}, {
  title: `What's the difference between Glaci Apps & Core Extensions?`,
  description: 'Glaci is a native application that just happens to have core system integration (e.g. Symitar). We differentiate the features that we provide within Glaci by their function type; <b>Glaci Apps</b> - standalone solutions within the Glaci platform. <b>Core Extensions</b> - workflows / tools within Glaci to augment the Symitar UX.',
}];

const PRICING_AND_AVAILABILITY: AccordionItem[] = [{
  title: 'How much does Glaci cost?',
  description: 'Pricing for Glaci is tailored for each client and is based on a variety of factors. We are also releasing a <b>free</b> version of Glaci that will have limited but powerful features that your employees will enjoy.'
}, {
  title: 'Where can I download Glaci?',
  description: `At the moment, Glaci is only available to credit unions participating in the Early Adopter Program (EAP). If you wish this were you, head on over to the <a href="${LIBUM_EAP_QUESTIONNAIRE}">Early Adopter Questionnaire</a> to have your credit union considered.`,
}];

const OTHER: AccordionItem[] = [{
  title: 'Do you support other core systems?',
  description: `At the moment, we're focused on Jack Henry™ credit unions on the Symitar core platform since it's our expertise. In the near future, we hope to expand to other core platforms like DNA® from Fiserv and Correlation's KeyStone. If you have other cores in mind, please <a href="${LIBUM_FEEDBACK}" target="_blank">let us know</a>!`,
},{
  title: 'What else is Libum working on?',
  description: `We're constantly working on new and exciting features. If you have any suggestions, you can <a href="${SALES_MAILTO}" target="_blank">reach out</a> to our team and we'll be happy see what we can do!`,
}];

function createGlaciFAQItems(items: AccordionItem[], group: GlaciFAQGroup): AccordionItem[] {
  return items.map((item, index) => ({
    ...item,
    id: `${item.title}-${index}`,
    group: group,
  }));
}

export const GLACI_FAQ_MENU = [
  {
    name: GlaciFAQGroup.GENERAL,
    id: 'faq-menu-glaci-general',
  },
  {
    name: GlaciFAQGroup.PRICING_AND_AVAILABILITY,
    id: 'faq-menu-glaci-pricing-and-availability',
  },
  {
    name: GlaciFAQGroup.OTHER,
    id: 'faq-menu-glaci-other',
  },
];

export const GLACI_GENERAL_FAQS: AccordionItem[] = createGlaciFAQItems(GENERAL, GlaciFAQGroup.GENERAL);
export const GLACI_PRICING_AND_AVAILABILITY_FAQS: AccordionItem[] = createGlaciFAQItems(PRICING_AND_AVAILABILITY, GlaciFAQGroup.PRICING_AND_AVAILABILITY);
export const GLACI_OTHER_FAQS: AccordionItem[] = createGlaciFAQItems(OTHER, GlaciFAQGroup.OTHER);

/* Features */

export enum GlaciFeatureType {
  APPS = 'apps',
  EXTS = 'exts',
}

export enum GlaciFeatureCategory {
  ALL = 'All Features',
  COMPLIANCE_AND_HR = 'Compliance & HR',
  CORE_WORKFLOWS = 'Core Workflows',
  DEVELOPER_TOOLS = 'Developer Tools',
  DATA_AND_ANALYTICS = 'Data & Analytics',
  OFFICE_ADMINISTRATION = 'Office Administration',
}

export type GlaciFeatureBenefit = {
  title: string;
  description: string;
  iconPath: string;
}

export enum GlaciFeatureStatus {
  AVAILABLE = 'Available',
  COMING_SOON = 'Coming Soon',
  IN_DEVELOPMENT = 'In Development',
}

export interface GlaciFeature {
  id: string;
  type: GlaciFeatureType;
  isFeatured: boolean;
  category: GlaciFeatureCategory;
  latestVersion: string;
  name: string;
  description: string;
  keywords: string[];
  summary: string;
  about: string[];
  benefits: GlaciFeatureBenefit[];
  status: GlaciFeatureStatus;
  iconPath: string;
  splashPathAnimated: string;
  splashPathStatic: string;
}

const DEFAULT_KEYWORDS = ['Software for credit unions', 'Software for Symitar', 'Software for Jack Henry'];

export const GLACI_FEATURES: GlaciFeature[] = [{
  id: 'analytics-live',
  type: GlaciFeatureType.APPS,
  isFeatured: true,
  category: GlaciFeatureCategory.DATA_AND_ANALYTICS,
  latestVersion: '--',
  name: 'Analytics Live',
  description: 'Visualize your Symitar data',
  keywords: ['Glaci','Glaci Features','Glaci App','analytics','Symitar core data','Symitar live data','realtime data','data visualization','member data',...DEFAULT_KEYWORDS],
  summary: 'An app that visualizes popular Symitar records through elegant and intuitive visual representations.',
  about: [
    'Symitar data is currently only available through PowerOn® or reporting solutions that take a nightly database extract. Either way, the institution must have a resource available that can either write reports using PowerOn® or T-SQL.',
    'The Analytics Live app offers another solution by leveraging a proprietary process that brings near real-time data to the fingertips of all end-users (non-SQL proficient included) that need them.',
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'TBD',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Near real-time datasets for popular Symitar records',
    iconPath: '/icons/twitter/time.png',
  }, {
    title: '',
    description: 'Enable users to combine datasets and produce elegant data visualizations',
    iconPath: '/icons/twitter/team.png',
  }, {
    title: '',
    description: 'Switch modes to view historical or most recently published data',
    iconPath: '/icons/twitter/chart-increasing.png',
  }, {
    title: '',
    description: 'Export reports in various formats with credit union branding',
    iconPath: '/icons/twitter/printer.png',
  }],
  status: GlaciFeatureStatus.COMING_SOON,
  iconPath: '/icons/glaci/analytics-live.svg',
  splashPathAnimated: '/images/glaci/analytics-live.gif',
  splashPathStatic: '/images/glaci/analytics-live.png',
}, {
  id: 'ctr-worksheets',
  type: GlaciFeatureType.EXTS,
  isFeatured: true,
  category: GlaciFeatureCategory.CORE_WORKFLOWS,
  latestVersion: '--',
  name: 'CTR Worksheets',
  description: 'Capture member & non-member data during a transaction',
  keywords: ['Glaci','Glaci Features','Glaci Core Extension','CTR worksheet','currency transaction report','transaction automation','member autofill','non-member data','currency reporting compliance','SymXchange','Symitar PowerOn','PowerOn',...DEFAULT_KEYWORDS],
  summary: 'An extension for Symitar transactions that allows you to capture member and non-member data during a transaction.',
  about: [
    'CTR Worksheets give your credit union peace-of-mind knowing that all transactions posted are strictly validated in real-time and all the necessary and relevant information is recorded with little-to-no monitoring required from your team.',
    `But don't worry, we have detailed reporting for when you do need to explore the data. This completely eliminates the risk of a CTR-related compliance nightmare due to human-error.`
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready (estimated)',
    description: 'October 2023',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Enforce worksheet completion before posting a transaction',
    iconPath: '/icons/twitter/ninja.png',
  }, {
    title: '',
    description: 'Automatically includes required owner information for the worksheet',
    iconPath: '/icons/twitter/mechanical-arm.png',
  }, {
    title: '',
    description: 'Comprehensive reporting for successful and discarded member interactions',
    iconPath: '/icons/twitter/printer.png',
  }, {
    title: '',
    description: 'Search existing members in Symitar to include as additional owners',
    iconPath: '/icons/twitter/magnifying.png',
  }],
  status: GlaciFeatureStatus.IN_DEVELOPMENT,
  iconPath: '/icons/glaci/ctr-worksheets.svg',
  splashPathAnimated: '/images/glaci/ctr-worksheets.gif',
  splashPathStatic: '/images/glaci/ctr-worksheets.png',
}, {
  id: 'cu-lambda',
  type: GlaciFeatureType.EXTS,
  isFeatured: true,
  category: GlaciFeatureCategory.DEVELOPER_TOOLS,
  latestVersion: '--',
  name: 'CU Lambda',
  description: 'Event-driven background-tasks for Symitar Quest',
  keywords: ['Glaci','Glaci Features','Glaci Core Extension','Symitar Quest automation','Symitar Quest events','background-tasks','Symitar lambda function','Symitar Quest API','SymXchange','Symitar PowerOn','PowerOn','Symitar file maintenance','Validation PowerOn file maintenance',...DEFAULT_KEYWORDS],
  summary: 'Trigger background tasks like sending emails, API calls, and even execute File Maintenance (FM) in Symitar records.',
  about: [
    'Often times credit unions have a need for additional tasks to take place on certain events. The CU Lambda extension removes the responsibility for the end-user to “remember” and introduces them to automation complete with auditing.',
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready (estimated)',
    description: 'October 2023',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Create custom event triggers to fire off Glaci provided micro-services',
    iconPath: '/icons/twitter/fire.png',
  }, {
    title: '',
    description: 'Bind conditional workflows to Symitar client events - including PowerOn®',
    iconPath: '/icons/twitter/wizard.png',
  }],
  status: GlaciFeatureStatus.IN_DEVELOPMENT,
  iconPath: '/icons/glaci/cu-lambda.svg',
  splashPathAnimated: '/images/glaci/cu-lambda.gif',
  splashPathStatic: '/images/glaci/cu-lambda.png',
}, {
  id: 'dormant-notes',
  type: GlaciFeatureType.EXTS,
  isFeatured: false,
  category: GlaciFeatureCategory.CORE_WORKFLOWS,
  latestVersion: 'Alpha 1.0.0',
  name: 'Dormant Notes',
  description: 'Stay in compliance with dormant accounts',
  keywords: ['Glaci','Glaci Feature','Glaci Core Extension','Dormant account','Dormant member','Dormant account compliance','Dormant account automation','Dormant member contact','Symitar Quest workflow','Symitar note','SymXchange','Symitar PowerOn','PowerOn',...DEFAULT_KEYWORDS],
  summary: 'An extension to capture critical information on first member contact to stay in federal compliance.',
  about: [
    'With the Dormant Notes extension, your team can now institute guidelines to be followed related to member inactivity to ensure that your institution is never out of compliance.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'August 2022',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Launches automatically when dormant member is accessed',
    iconPath: '/icons/twitter/sparkles.png',
  }, {
    title: '',
    description: 'Prevent transaction from posting until workflow is completed',
    iconPath: '/icons/twitter/ninja.png',
  }, {
    title: '',
    description: 'Updates account notes, fields, and warnings post completion',
    iconPath: '/icons/twitter/mechanical-arm.png',
  }, {
    title: '',
    description: 'In-depth reports that detail completion or override',
    iconPath: '/icons/twitter/printer.png',
  }],
  status: GlaciFeatureStatus.AVAILABLE,
  iconPath: '/icons/glaci/dormant-notes.svg',
  splashPathAnimated: '/images/glaci/dormant-notes.gif',
  splashPathStatic: '/images/glaci/dormant-notes.png',
}, {
  id: 'dynamic-overlay',
  type: GlaciFeatureType.EXTS,
  isFeatured: true,
  category: GlaciFeatureCategory.CORE_WORKFLOWS,
  latestVersion: 'Alpha 1.0.0',
  name: 'Dynamic Overlay',
  description: 'Responsive Glaci workflows in your Symitar Quest UI',
  keywords: ['Glaci','Glaci Feature','Glaci Core Extension','Symitar overlay','AI','artificial intelligence','CRM','member relationship manager','Symitar Quest automation','Symitar Quest workflows','SymXchange','PowerOn','member engagement','member leads','member tracking','Credit union goals','interactive',...DEFAULT_KEYWORDS],
  summary: `A configurable extension with a responsive UI in Symitar Quest, so you understand your member's needs as you serve them.`,
  about: [
    'Dynamic Overlay introduces a new layer to the Symitar Quest UI that provides intelligent suggestions to your team as they serve members without switching any screens.',
    'It is an interactive extension that can be configured to ensure critical information is always top of screen and positioned anywhere that feels right to any user.',
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready (estimated)',
    description: 'October 2023',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Service members with powerful insight into their needs',
    iconPath: '/icons/twitter/lightbulb.png',
  }, {
    title: '',
    description: 'Give your members opportunities they may have otherwise missed',
    iconPath: '/icons/twitter/gift.png',
  }, {
    title: '',
    description: 'Launch mini-workflows in parallel with typical Symitar actions',
    iconPath: '/icons/twitter/rocket.png',
  }],
  status: GlaciFeatureStatus.IN_DEVELOPMENT,
  iconPath: '/icons/glaci/dynamic-overlay.svg',
  splashPathAnimated: '/images/glaci/dynamic-overlay.gif',
  splashPathStatic: '/images/glaci/dynamic-overlay.png',
}, {
  id: 'lobby-manager',
  type: GlaciFeatureType.APPS,
  isFeatured: false,
  category: GlaciFeatureCategory.OFFICE_ADMINISTRATION,
  latestVersion: '--',
  name: 'Lobby Manager',
  description: 'Handle queues and employee availability with ease',
  keywords: ['Glaci','Glaci Feature','Glaci App','branch queues','lobby management','AI','artificial intelligence','Symitar integration','SymXchange','PowerOn','analytics',...DEFAULT_KEYWORDS],
  summary: 'An app to handle queues and employee availability for your new and existing members.',
  about: [
    'Lobby Manager bridges the gap between member visits and work done in the Symitar core. Now you can better understand why members visit by cross analyzing visit reasons with time of visit, duration, and frequency.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'TBD',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Predictive custom visit reasons to quickly sign in members',
    iconPath: '/icons/twitter/brain.png',
  }, {
    title: '',
    description: 'Reduce input time with branch detection, Symitar integration, and more',
    iconPath: '/icons/twitter/time.png',
  }, {
    title: '',
    description: 'Track member visit metrics and trends in Analytics Live',
    iconPath: '/icons/twitter/chart-increasing.png',
  }],
  status: GlaciFeatureStatus.COMING_SOON,
  iconPath: '/icons/glaci/lobby-manager.svg',
  splashPathAnimated: '/images/glaci/lobby-manager.gif',
  splashPathStatic: '/images/glaci/lobby-manager.png',
}, {
  id: 'marketing-automator',
  type: GlaciFeatureType.APPS,
  isFeatured: false,
  category: GlaciFeatureCategory.DATA_AND_ANALYTICS,
  latestVersion: '--',
  name: 'Marketing Automator',
  description: 'Run marketing campaigns and track results',
  keywords: ['Glaci','Glaci Feature','Glaci App','email marketing','survey marketing','email campaigns','response metrics','member engagement','membership reports','Symitar integration','SymXchange','PowerOn','analytics',...DEFAULT_KEYWORDS],
  summary: 'Create and deliver personalized emails and surveys for any campaign, at any time.',
  about: [
    'Marketing Automator grants your “in-house” marketing team full autonomy of all email and survey campaigns along with access to vital metrics such as delivery and open-rate.',
    'Additionally, it is smart enough to know what emails are no longer valid and can give Symitar a heads up, the next time member contact is made.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'TBD',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Create and send emails to your membership on any interval',
    iconPath: '/icons/twitter/envelope.png',
  }, {
    title: '',
    description: 'Understand member engagement with in-depth reporting and metrics',
    iconPath: '/icons/twitter/bar-chart.png',
  }, {
    title: '',
    description: 'Upload custom lists or use Analytics Live to target members',
    iconPath: '/icons/twitter/ufo.png',
  }, {
    title: '',
    description: 'Send surveys to collect critical information from your members',
    iconPath: '/icons/twitter/memo.png',
  }],
  status: GlaciFeatureStatus.COMING_SOON,
  iconPath: '/icons/glaci/marketing-automator.svg',
  splashPathAnimated: '/images/glaci/marketing-automator.gif',
  splashPathStatic: '/images/glaci/marketing-automator.png',
}, {
  id: 'microsoft-365',
  type: GlaciFeatureType.APPS,
  isFeatured: true,
  category: GlaciFeatureCategory.OFFICE_ADMINISTRATION,
  latestVersion: 'Alpha 1.0.0',
  name: 'Microsoft 365',
  description: 'Access your full Office 365 suite',
  keywords: ['Glaci','Glaci Feature','Glaci App','Microsoft 365','Microsoft Office',...DEFAULT_KEYWORDS],
  summary: 'Immersive Microsoft 365 platform support.',
  about: [
    'Give your employees access to all of Microsoft 365 from within Glaci to send emails, message on Teams, and more all from within the platform.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Free',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'September 2022',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Enables popular Microsoft 365 apps from within Glaci, just for convenience',
    iconPath: '/icons/twitter/party-popper.png',
  }],
  status: GlaciFeatureStatus.AVAILABLE,
  iconPath: '/icons/glaci/microsoft-365.svg',
  splashPathAnimated: '/images/glaci/microsoft-365.gif',
  splashPathStatic: '/images/glaci/microsoft-365.png',
}, {
  id: 'password-manager',
  type: GlaciFeatureType.APPS,
  isFeatured: true,
  category: GlaciFeatureCategory.OFFICE_ADMINISTRATION,
  latestVersion: 'Alpha 1.0.0',
  name: 'Password Manager',
  description: 'Secure passwords & other sensitive data',
  keywords: ['Glaci','Glaci Feature','Glaci App','password manager','password storage','password generator','increase security','password encryption',...DEFAULT_KEYWORDS],
  summary: `An app for securely storing and managing users' strong passwords and other sensitive data.`,
  about: [
    'Password Manager enables your credit union to give its employees a frictionless user experience within Glaci while lessening the burden for IT and giving more granular control and increased security.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'September 2022',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Only memorize a single, master password to protect them all',
    iconPath: '/icons/twitter/shield.png',
  }, {
    title: '',
    description: `Increase security by managing and centralizing employees' access points`,
    iconPath: '/icons/twitter/detective.png',
  }],
  status: GlaciFeatureStatus.AVAILABLE,
  iconPath: '/icons/glaci/password-manager.svg',
  splashPathAnimated: '/images/glaci/password-manager.gif',
  splashPathStatic: '/images/glaci/password-manager.png',
}, {
  id: 'user-access-manager',
  type: GlaciFeatureType.APPS,
  isFeatured: false,
  category: GlaciFeatureCategory.COMPLIANCE_AND_HR,
  latestVersion: '--',
  name: 'User Access Manager',
  description: 'Bring employee automation to your credit union',
  keywords: ['Glaci','Glaci Feature','Glaci App','user administration','employee automation','workflows','third-party integration','auditing','compliance','Symitar employee automation','Symitar onboarding','SymXchange','PowerOn',...DEFAULT_KEYWORDS],
  summary: 'Onboard, update, and offboard your credit union employees through configurable workflows.',
  about: [
    'Take control of the user administration within your credit union with User Access Manager and build workflows that automate your employees’ access. This reduces human error, quickens the “request-to-receive” for user access, and strengthens security within your institution.',
    'User Access Manager is also equipped with in-depth reports that identify which users have access to an app and vice versa.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'TBD',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Build workflows that require respective party approval',
    iconPath: '/icons/twitter/ninja.png',
  }, {
    title: '',
    description: 'Streamline auditing and compliance',
    iconPath: '/icons/twitter/mechanical-arm.png',
  }, {
    title: '',
    description: 'Create, update, and remove Symitar users',
    iconPath: '/icons/twitter/artist-palette.png',
  }, {
    title: '',
    description: 'Evolving list of supported third-party applications',
    iconPath: '/icons/twitter/fire.png',
  }],
  status: GlaciFeatureStatus.COMING_SOON,
  iconPath: '/icons/glaci/user-access-manager.svg',
  splashPathAnimated: '/images/glaci/user-access-manager.gif',
  splashPathStatic: '/images/glaci/user-access-manager.png',
}, {
  id: 'wire-manager',
  type: GlaciFeatureType.EXTS,
  isFeatured: false,
  category: GlaciFeatureCategory.CORE_WORKFLOWS,
  latestVersion: '--',
  name: 'Wire Manager',
  description: 'Augment your wire transfer experience within Symitar',
  keywords: ['Glaci','Glaci Feature','Glaci App','wire transfer','wire automation','historical data','autofill','dual-control','comprehensive receipts','ABA lookup','OFAC checking','Symitar Quest integration','Symitar Quest user experience','Symitar Quest wire transfer','SymXchange','PowerOn',...DEFAULT_KEYWORDS],
  summary: 'An augmented wire transfer UX in Symitar with automated transactions, back-office queues, and more.',
  about: [
    'Centralize your incoming and outgoing wire transfers for both the front-office and back-office staff with the Wire Manager extension.',
    `Fully integrated within Symitar, it serves as a replacement for the typical TranCode procedure with an immersive workflow which spans multiple involved departments.`,
    'The Wire Manager extension was designed with the intention of centralizing the complete transaction providing your credit union with a secure and thorough wire transfer process.'
  ],
  benefits: [{
    title: 'Billing tier',
    description: 'Subscription',
    iconPath: '/icons/twitter/money.png',
  }, {
    title: 'Feature ready',
    description: 'TBD',
    iconPath: '/icons/twitter/calendar.png',
  }, {
    title: '',
    description: 'Predictive form autofill based on previous wire transactions',
    iconPath: '/icons/twitter/brain.png',
  }, {
    title: '',
    description: 'Back-office queues (dual-control) for callback, verification, and release',
    iconPath: '/icons/twitter/team.png',
  }, {
    title: '',
    description: 'Automated transactions with comprehensive receipts',
    iconPath: '/icons/twitter/mechanical-arm.png',
  }, {
    title: '',
    description: 'Responsive ABA lookup and simultaneous OFAC checking',
    iconPath: '/icons/twitter/magnifying.png',
  }],
  status: GlaciFeatureStatus.COMING_SOON,
  iconPath: '/icons/glaci/wire-manager.svg',
  splashPathAnimated: '/images/glaci/wire-manager.gif',
  splashPathStatic: '/images/glaci/wire-manager.png',
}];

export const getGlaciFeature = (id: string) => GLACI_FEATURES.find((feature) => feature.id === id);

/* Pricing */

function getGlaciFeatureNameForPlan(id: string): string {
  const feature = getGlaciFeature(id);
  return `<img src="${feature?.iconPath || '/icons/glaci/mystery.svg'}" />${feature?.name || 'Mystery App'}`;
}

const GLACI: PlanFeature[] = [{
  name: `Integration with the Symitar core platform by Jack Henry™`,
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: 'Feature updates delivered over-the-air',
  offerings: [{
    name: PlanOfferings.GLACI_FREE,
  }, {
    name: PlanOfferings.GLACI,
  }],
},{
  name: 'Symitar in-app overlay',
  offerings: [{
    name: PlanOfferings.GLACI,
    description: 'Standard',
  }],
}, {
  name: 'Single Sign-On (SSO)',
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('analytics-live'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('ctr-worksheets'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('cu-lambda'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('dormant-notes'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('dynamic-overlay'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('lobby-manager'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('marketing-automator'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('microsoft-365'),
  offerings: [{
    name: PlanOfferings.GLACI_FREE,
  }, {
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('password-manager'),
  offerings: [{
    name: PlanOfferings.GLACI_FREE,
    description: 'Up to 10 items'
  }, {
    name: PlanOfferings.GLACI,
  }],
}, {
  name: `<img src="/icons/glaci/smug.svg"><div>SMUG <span style="margin-left:4px;font-size:12px">*development pending feedback from SMUG roundtable</span></div>`,
  offerings: [{
    name: PlanOfferings.GLACI_FREE,
  }, {
    name: PlanOfferings.GLACI,
  }],
}, {
  name: getGlaciFeatureNameForPlan('user-access-manager'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }]
}, {
  name: getGlaciFeatureNameForPlan('wire-manager'),
  offerings: [{
    name: PlanOfferings.GLACI,
  }],
}];

const GLACI_PLAN_FEATURES: PlanFeature[] = createItems(GLACI, 'glaci');

export const GLACI_PLAN: PlanProps = {
  header: `Use Glaci for free, or subscribe to next-level features`,
  features: GLACI_PLAN_FEATURES,
  offerings: [
    {
      name: PlanOfferings.GLACI_FREE,
      icon: '/logos/glaci_free_color.svg',
      id: 'offering-glaci-free',
      class: 'glaci',
      link: {
        href: '',
        text: 'Coming Soon',
      },
    },
    {
      name: PlanOfferings.GLACI,
      icon: '/logos/glaci_color.svg',
      id: 'offering-glaci',
      class: 'glaci',
      link: {
        href: LIBUM_SCHEDULING_DEMO_DYLAN,
        text: 'Schedule Demo',
      },
    },
  ],
}
