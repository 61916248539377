import { useRef, useState } from 'react';
import { useRouter } from 'next/router';

import Link from 'next/link';

import {
  HeaderPopupClose,
  HeaderPopupContainer,
  HeaderPopupContent,
  HeaderPopupContentContainer,
  HeaderPopupFooter,
  HeaderPopupFooterLinkDefault,
  HeaderPopupFooterLinkPrimary,
  HeaderPopupMenu,
  HeaderPopupSection,
  HeaderPopupSectionLinks,
  HeaderPopupSectionTitle,
} from './header-popup.styles';

import Icon from 'shared/components/icon';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';

import { HEADER_POPUP_ITEMS } from 'shared/constants/nav-items';
import { HeaderStructure } from '../header.structure';
import { LIBUM_EAP_QUESTIONNAIRE } from 'shared/constants/globals';

import MENU_OUTLINE from 'public/icons/menu_outline.svg';
import MENU_CLOSE from 'public/icons/menu_close.svg';
import HOVER_ARROW from 'public/icons/hover_arrow.svg';
import { handleExternallLink } from 'shared/constants/utilities';

type HeaderPopupProps = {
  is404?: boolean;
  theme?: 'primary' | 'secondary';
};

export default function HeaderPopup({ is404, theme }: HeaderPopupProps) {
  const ref = useRef(null);
  const router = useRouter();
  const [isActive, setActive] = useState(false);

  if (typeof window !== 'undefined') {
    const e = new HeaderStructure().touch.isSupported ? 'touchend' : 'click';
    // eslint-disable-next-line
    useOnClickOutside(ref, () => setActive(false), e);
  }

  const togglePopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e?.preventDefault();
    setActive(!isActive);
  };

  return (
    <HeaderPopupContainer ref={ref}>
      {/* Header popup menu */}
      <Link href="" passHref legacyBehavior>
        <HeaderPopupMenu onClick={(e) => togglePopup(e)}>
          <MENU_OUTLINE height={38} className={is404 ? 'primary' : theme} />
        </HeaderPopupMenu>
      </Link>
      {/* Header popup */}
      <HeaderPopupContentContainer isVisible={isActive}>
        <HeaderPopupContent className={isActive ? 'active' : ''}>
          {/* Close button */}
          <HeaderPopupClose onClick={(e) => togglePopup(e)}>
            <MENU_CLOSE height={32} />
          </HeaderPopupClose>
          {/* Content */}
          {HEADER_POPUP_ITEMS.map((item, idx) => (
            <HeaderPopupSection className={idx === HEADER_POPUP_ITEMS.length - 1 ? 'last' : ''} key={item.id}>
              <HeaderPopupSectionTitle>{item.name}</HeaderPopupSectionTitle>
              <HeaderPopupSectionLinks>
                {item.children?.map((child) => (
                  <Link
                    href=""
                    key={child.id}
                    onClick={(e) => {
                      setActive(false);
                      handleExternallLink(e, child?.link || '');
                    }}
                  >
                    {child.icon && (
                      <Icon
                        name={child.icon.name}
                        type={child.icon.type}
                        className={child.icon.type == 'eva' ? 'generic' : ''}
                      />
                    )}
                    <span className="text-overflow">{child.name}</span>
                  </Link>
                ))}
              </HeaderPopupSectionLinks>
            </HeaderPopupSection>
          ))}
          {/* Footer */}
          <HeaderPopupFooter>
            <Link href="" passHref legacyBehavior>
              <HeaderPopupFooterLinkDefault onClick={(e) => handleExternallLink(e, '/pricing')}>
                Pricing
              </HeaderPopupFooterLinkDefault>
            </Link>
            <Link href="" passHref legacyBehavior>
              <HeaderPopupFooterLinkPrimary onClick={(e) => handleExternallLink(e, LIBUM_EAP_QUESTIONNAIRE)}>
                Early Access
                <HOVER_ARROW />
              </HeaderPopupFooterLinkPrimary>
            </Link>
          </HeaderPopupFooter>
        </HeaderPopupContent>
      </HeaderPopupContentContainer>
    </HeaderPopupContainer>
  );
}
