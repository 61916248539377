import Link from 'next/link';

import {
  HeaderDropdownFeatured,
  HeaderDropdownFeaturedColumn,
  HeaderDropdownFeaturedColumnHeader,
  HeaderDropdownFooterLink,
  HeaderDropdownFooterLinkList,
  HeaderDropdownHeader,
  HeaderDropdownSection,
} from '../header-dropdown-contents.styles';
import {
  HeaderDropdownGlaciContainer,
  HeaderDropdownGlaciFeature,
  HeaderDropdownGlaciFeatureDetails,
  HeaderDropdownGlaciFeaturedList,
} from './glaci-dropdown.styles';
import { GlaciFeature, GlaciFeatureType, GLACI_FEATURES } from 'shared/data/glaci';

import HOVER_ARROW_SMALL from 'public/icons/hover_arrow_small.svg';
import Compass from 'public/icons/eva/compass.svg';
import Keypad from 'public/icons/eva/keypad.svg';
import Pricetag from 'public/icons/eva/pricetag.svg';

const featuredApps: GlaciFeature[] = [
  ...GLACI_FEATURES.filter((f) => f.isFeatured && f.type === GlaciFeatureType.APPS),
];

const featuredExts: GlaciFeature[] = [
  ...GLACI_FEATURES.filter((f) => f.isFeatured && f.type === GlaciFeatureType.EXTS),
];

export default function GlaciDropdown() {
  return (
    <HeaderDropdownGlaciContainer>
      {/* Header and featured */}
      <HeaderDropdownSection data-first-dropdown-section>
        <HeaderDropdownHeader>
          Supercharge your credit union with a modern platform to improve operational efficiencies and the UX for your
          employees in & out of the core
        </HeaderDropdownHeader>
        <HeaderDropdownFeatured>
          {/* Glaci Apps */}
          <HeaderDropdownFeaturedColumn>
            <HeaderDropdownFeaturedColumnHeader>Glaci Apps</HeaderDropdownFeaturedColumnHeader>
            <HeaderDropdownGlaciFeaturedList>
              {/* Featured apps */}
              {featuredApps.map((app, i) => (
                <HeaderDropdownGlaciFeature key={i}>
                  <Link href={`/glaci/${app.id}`}>
                    {/* eslint-disable-next-line */}
                    <img src={app.iconPath} />
                    <HeaderDropdownGlaciFeatureDetails>
                      <h1>
                        {app.name}
                        <HOVER_ARROW_SMALL />
                      </h1>
                      <h2>{app.description}</h2>
                    </HeaderDropdownGlaciFeatureDetails>
                  </Link>
                </HeaderDropdownGlaciFeature>
              ))}
            </HeaderDropdownGlaciFeaturedList>
          </HeaderDropdownFeaturedColumn>
          {/* Core Extensions */}
          <HeaderDropdownFeaturedColumn>
            <HeaderDropdownFeaturedColumnHeader>Core Extensions</HeaderDropdownFeaturedColumnHeader>
            <HeaderDropdownGlaciFeaturedList>
              {/* Featured extensions */}
              {featuredExts.map((ext, i) => (
                <HeaderDropdownGlaciFeature key={i}>
                  <Link href={`/glaci/${ext.id}`}>
                    {/* eslint-disable-next-line */}
                    <img src={ext.iconPath} />
                    <HeaderDropdownGlaciFeatureDetails>
                      <h1>
                        {ext.name}
                        <HOVER_ARROW_SMALL />
                      </h1>
                      <h2>{ext.description}</h2>
                    </HeaderDropdownGlaciFeatureDetails>
                  </Link>
                </HeaderDropdownGlaciFeature>
              ))}
            </HeaderDropdownGlaciFeaturedList>
          </HeaderDropdownFeaturedColumn>
        </HeaderDropdownFeatured>
      </HeaderDropdownSection>
      {/* Footer and links */}
      <HeaderDropdownSection>
        <HeaderDropdownFooterLinkList>
          <HeaderDropdownFooterLink>
            <Link href="/glaci">
              <Compass />
              Overview
            </Link>
          </HeaderDropdownFooterLink>
          <HeaderDropdownFooterLink>
            <Link href="/glaci#explore">
              <Keypad />
              Apps&nbsp;&&nbsp;Exts
            </Link>
          </HeaderDropdownFooterLink>
          <HeaderDropdownFooterLink>
            <Link href="/glaci#plan">
              <Pricetag />
              Pricing
            </Link>
          </HeaderDropdownFooterLink>
        </HeaderDropdownFooterLinkList>
      </HeaderDropdownSection>
    </HeaderDropdownGlaciContainer>
  );
}
