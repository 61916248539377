import Link from 'next/link';

import {
  HeaderDropdownFeatured,
  HeaderDropdownFeaturedColumn,
  HeaderDropdownFeaturedColumnHeader,
  HeaderDropdownHeader,
  HeaderDropdownSection,
  HeaderDropdownLink,
  HeaderDropdownFooterLink,
  HeaderDropdownFooterLinkList,
} from '../header-dropdown-contents.styles';
import { HeaderDropdownNexumAPIContainer, HeaderDropdownNexumAPIFeaturedList } from './nexum-dropdown.styles';

import { LIBUM_API_NEXUM, LIBUM_STATUS } from 'shared/constants/globals';

import Book from 'public/icons/eva/book.svg';
import Activity from 'public/icons/eva/activity.svg';
import Pricetag from 'public/icons/eva/pricetag.svg';
import { handleExternallLink } from 'shared/constants/utilities';

export default function NexumAPIDropdown() {
  return (
    <HeaderDropdownNexumAPIContainer>
      {/* Header and featured */}
      <HeaderDropdownSection data-first-dropdown-section>
        <HeaderDropdownHeader>
          Give your devs some<b>&nbsp;REST&nbsp;</b>when using SymXchange™
        </HeaderDropdownHeader>
        <HeaderDropdownFeatured>
          {/* Features */}
          <HeaderDropdownFeaturedColumn>
            <HeaderDropdownFeaturedColumnHeader>Features</HeaderDropdownFeaturedColumnHeader>
            <HeaderDropdownNexumAPIFeaturedList>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum">Overview</Link>
              </HeaderDropdownLink>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#naas">NaaS</Link>
              </HeaderDropdownLink>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#chained-requests">Chained requests</Link>
              </HeaderDropdownLink>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#improved-logging">Improved logging</Link>
              </HeaderDropdownLink>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#multi-version-support">Multi-version support</Link>
              </HeaderDropdownLink>
            </HeaderDropdownNexumAPIFeaturedList>
          </HeaderDropdownFeaturedColumn>
          {/* Offerings */}
          <HeaderDropdownFeaturedColumn>
            <HeaderDropdownFeaturedColumnHeader>Offerings</HeaderDropdownFeaturedColumnHeader>
            <HeaderDropdownNexumAPIFeaturedList>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#plan">Libum-hosted</Link>
              </HeaderDropdownLink>
              <HeaderDropdownLink className="feature">
                <Link href="/nexum#plan">Self-hosted</Link>
              </HeaderDropdownLink>
            </HeaderDropdownNexumAPIFeaturedList>
          </HeaderDropdownFeaturedColumn>
        </HeaderDropdownFeatured>
      </HeaderDropdownSection>
      {/* Footer links */}
      <HeaderDropdownSection>
        <HeaderDropdownFooterLinkList>
          <HeaderDropdownFooterLink>
            <Link href="" onClick={(e) => handleExternallLink(e, LIBUM_API_NEXUM)}>
              <Book />
              API&nbsp;Reference
            </Link>
          </HeaderDropdownFooterLink>
          <HeaderDropdownFooterLink>
            <Link href="" onClick={(e) => handleExternallLink(e, LIBUM_STATUS)}>
              <Activity />
              API&nbsp;Status
            </Link>
          </HeaderDropdownFooterLink>
          <HeaderDropdownFooterLink>
            <Link href="/nexum#plan">
              <Pricetag />
              Offerings
            </Link>
          </HeaderDropdownFooterLink>
        </HeaderDropdownFooterLinkList>
      </HeaderDropdownSection>
    </HeaderDropdownNexumAPIContainer>
  );
}
