import styled from 'styled-components';

export const HeaderDropdownSection = styled.div`
  position: relative;
  padding: 24px 32px;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const HeaderDropdownHeader = styled.h3`
  padding-bottom: 16px;
  color: ${(props) => props.theme.colors.gray600};
  font-family: ${({ theme }) => theme.fontFamily.display};
  font-size: 15px;
  font-weight: 500;
  text-align: left;
`;

export const HeaderDropdownFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const HeaderDropdownFeaturedColumn = styled.div`
  flex: 1 0 50%;
`;

export const HeaderDropdownFeaturedColumnHeader = styled.h4`
  display: flex;
  margin-top: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.textQuaternary};
  font-family: ${(props) => props.theme.fontFamily.headline};
  font-size: 14px;
`;

export const HeaderDropdownLink = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:not(.feature) {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  a {
    display: flex;
    align-items: center;
    padding: 0;

    &:hover {
      opacity: 1 !important;
    }
  }

  svg {
    fill: ${(props) => props.theme.colors.gray400};
    margin-right: 8px;
    height: 16px;
  }

  &:hover svg {
    fill: ${(props) => props.theme.colors.primary};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const HeaderDropdownFooterLinkList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HeaderDropdownFooterLink = styled.li`
  padding: 8px 16px;
  color: ${(props) => props.theme.colors.textQuaternary};
  font-family: ${(props) => props.theme.fontFamily.headline};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    padding: 0;

    &:hover {
      color: ${(props) => props.theme.colors.textPrimary};

      svg {
        fill: ${(props) => props.theme.colors.textPrimary} !important;
      }
    }

    & > svg {
      margin-top: 2px;
      margin-right: 8px;
      height: 16px;
      fill: ${(props) => props.theme.colors.textQuaternary} !important;
    }
  }

  :first-of-type {
    padding-left: 0;
  }

  :last-of-type {
    padding-right: 0;
  }
`;
