import Image from 'next/image';
import Link from 'next/link';
import FooterItem from '../footer-item';
import {
  FooterActions,
  FooterCopyright,
  FooterInfo,
  FooterInfoHeader,
  FooterInfoLocation,
  FooterInfoSocials,
  FooterItemsContainer,
  FooterItemsDivider,
  FooterItemsSpacer,
} from './footer-items.styles';

import { LINKEDIN_URL, GITHUB_URL } from 'shared/constants/globals';
import { NavigationItem } from 'shared/constants/nav-items';

import AMERICA from 'public/icons/twitter/america.png';
import LIBUM_WHITE from 'public/logos/libum_white.svg';
import LINKEDIN from 'public/icons/eva/linkedin.svg';
import GITHUB from 'public/icons/eva/github.svg';
import { getCurrentYear } from 'shared/constants/utilities';

type FooterItemsProps = {
  items: NavigationItem[];
};

export default function FooterItems({ items }: FooterItemsProps) {
  return (
    <>
      {/* First section of Footer items (e.g. slogan, routes, etc.) */}
      <FooterItemsContainer>
        {/* Footer information (e.g. Header, Location, and Socials) */}
        <FooterInfo>
          <FooterInfoHeader>
            Making <span>credit</span>
            <br />
            <span>union</span> software
            <br />a piece of cake
          </FooterInfoHeader>
          <FooterInfoLocation>
            <Image src={AMERICA} alt="" height={18}></Image>
            Austin, TX
          </FooterInfoLocation>
          <FooterInfoSocials>
            <Link href={LINKEDIN_URL} target="_blank">
              <LINKEDIN />
            </Link>
            <Link href={GITHUB_URL} target="_blank">
              <GITHUB />
            </Link>
          </FooterInfoSocials>
        </FooterInfo>
        <FooterItemsSpacer />
        {/* Footer routes (e.g. Products, Company, Resources) */}
        {items.map((item) => (
          <FooterItem item={item} key={item.id}></FooterItem>
        ))}
      </FooterItemsContainer>
      <FooterItemsDivider />
      {/* Second section of Footer items (e.g. logo & legal) */}
      <FooterItemsContainer>
        <FooterActions>
          <Link href="/">
            <LIBUM_WHITE height={28} />
          </Link>
          <FooterCopyright>© {getCurrentYear()} Libum, LLC</FooterCopyright>
        </FooterActions>
      </FooterItemsContainer>
    </>
  );
}
