import styled from 'styled-components';

export const HeaderDropdownNexumAPIContainer = styled.div`
  width: 460px;
`;

export const HeaderDropdownNexumAPIFeaturedList = styled.ul`
  font-weight: 500;
  color: ${(props) => props.theme.colors.gray600};

  li {
    line-height: 16px;

    &:hover {
      color: ${(props) => props.theme.colors.textPrimary};
      cursor: pointer;
    }
  }
`;
