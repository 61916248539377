import { DefaultTheme } from "styled-components";


export const theme: DefaultTheme = {
  fontFamily: {
    display: `'Palanquin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    headline: `'Palanquin Dark', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
  },
  colors: {
    primary: '#291752',
    secondary: '#516AB2',
    bgPrimary: '#FFFFFF',
    bgSecondary: '#2E3A59',
    bgColor: '#516AB2',
    bgGray: '#EDF1F7',
    textPrimary: '#291752',
    textSecondary: '#FFFFFF',
    textTertiary: '#4EC7E5',
    textQuaternary: '#516AB2',
    /* Lightened (50%) */
    textQuaternaryLight: '#D0E9FF',
    sloganPrimary: '#4EC7E5',
    sloganSecondary: '#516AB2',
    gray100: '#F7F9FC',
    gray200: '#EDF1F7',
    gray300: '#E4E9f2',
    gray400: '#C5CEE0',
    gray500: '#8F9BB3',
    gray600: '#76829A',
    gray700: '#5C6880',
    gray800: '#2E3A59',
    gray900: '#222B45',
    gray1000: '#192038',
    gray1100: '#151A30',
    gray1200: '#101426',
    error: '#FF5047',
    success: '#00D68F',
    warning: '#FFBA44',
    /* Vendor colors */
    linkedin: '#0072b1',
    github: '#171515'
  }
};
