import { useRouter } from 'next/router';

import Footer from './footer';
import Header from './header';

import { ContentContainer, LayoutContainer } from './layout.styles';

import { SECONDARY_THEME_ROUTES } from 'shared/constants/globals';
import { matchInArray } from 'shared/constants/utilities';

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Layout({ children, className }: LayoutProps) {
  const router = useRouter();
  const theme = matchInArray(router.pathname, SECONDARY_THEME_ROUTES) ? 'secondary' : 'primary';
  const is404 = router.pathname === '/404';

  return (
    <LayoutContainer className={className}>
      <Header className={is404 ? 'gray' : theme} />
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  );
}
