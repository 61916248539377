import styled from "styled-components";

/* Devices */

const size = {
  mobile: '479px',
  tablet: '768px',
  tabletL: '991px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
}

export const devices = {
  mobileMin: `screen and (min-width: ${size.mobile})`,
  mobileMax: `screen and (max-width: ${size.mobile})`,
  tabletMin: `screen and (min-width: ${size.tablet})`,
  tabletMax: `screen and (max-width: ${size.tablet})`,
  tabletLMin: `screen and (min-width: ${size.tabletL})`,
  tabletLMax: `screen and (max-width: ${size.tabletL})`,
  laptopSMin: `screen and (min-width: ${size.laptopS})`,
  laptopSMax: `screen and (max-width: ${size.laptopS})`,
  laptopMMin: `screen and (min-width: ${size.laptopM})`,
  laptopMMax: `screen and (max-width: ${size.laptopM})`,
  laptopLMin: `screen and (min-width: ${size.laptopL})`,
  laptopLMax: `screen and (max-width: ${size.laptopL})`,
}

/* Links */

export const PrimaryLink = styled.a`
  display: inline-block;
  padding-left: 16px;
  padding-right: 12px;
  color: ${(props) => props.theme.colors.textPrimary};
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 16px;
  font-size: 15px;
  font-weight: 700;
  line-height: 38px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  svg.hover_arrow_svg__hover-arrow {
    fill: transparent;
  }
`;

export const SecondaryLink = styled.a`
  color: ${(props) => props.theme.colors.textQuaternary};
  border-radius: 16px;
  font-size: 15px;
  font-weight: 700;
  line-height: 38px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  svg.hover_arrow_svg__hover-arrow {
    fill: transparent;
  }
`;

/* Block */

export const BlockContainer = styled.div<{ backgroundColor: string }>`
  display: block;
  margin-top: -120px;
  margin-bottom: 80px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 70px 40px;
  max-width: 1180px;
  width: 90%;
  border-radius: 16px;
  background-color: ${(props) => props.backgroundColor === "gray" ? props.theme.colors.gray100 : props.theme.colors.white};

  h3 {
    color: ${(props) => props.theme.colors.sloganSecondary};
    font-weight: 600;
  }

  .subtitle {
    margin-top: 20px;
  }

  a {
    color: ${(props) => props.theme.colors.textSecondary};
    background-color: ${(props) => props.theme.colors.sloganSecondary};
  }

  .paragraph {
    margin-bottom: 16px;
  }

  @media ${devices.laptopLMin} {
    padding-right: 100px;
    padding-left: 100px;
  }

  @media ${devices.tabletMax} {
    padding: 32px 33px 33px;
  }

  @media ${devices.mobileMax} {
    padding-right: 25px;
    padding-left: 25px;

    .paragraph {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

/* Flex */

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-start;
  justify-content: space-between;
  overflow: hidden;
  width: 90%;
  height: auto;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;

  .subtitle {
    margin-top: 24px;
  }

  &.left-vert {
    flex-direction: column;
    align-items: flex-start;
  }

  &.right-vert {
    flex-direction: column;
    align-items: flex-end;
  }

  @media ${devices.laptopMMin} {
    justify-content: space-between;

    .paragraph {
      margin-bottom: 0;
      text-align: left;
    }
  }

  @media ${devices.tabletLMax} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${devices.mobileMax} {
    .paragraph {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const FlexLandingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--page-section-padding);
  height: 732px;

  @media ${devices.mobileMax} {
    height: auto;
  }

  @media ${devices.laptopSMin} {
    height: auto;
    padding-bottom: 60px;
  }
`;

export const FlexLandingText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  justify-content: center;
  max-width: 550px;
`;

export const FlexLandingImage = styled.img`
  display: inline-block;
  max-width: 100%;
`

export const FlexContentSection = styled.div`
  padding-top: 56px;
  padding-bottom: 56px;

  &.last-item {
    padding-bottom: 168px;
  }

  &.bg-gray {
    background-color: ${(props) => props.theme.colors.bgGray};
  }
  &.bg-secondary {
    background-color: ${(props) => props.theme.colors.bgSecondary};
  }

  @media ${devices.mobileMax} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const FlexContentSubSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;

  @media ${devices.laptopLMin} {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media ${devices.mobileMax} {
    align-items: center;
    flex-direction: column;
  }
`;

export const FlexContentSubSectionText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;

  @media ${devices.laptopMMin} {
    &.paragraph {
      margin-bottom: 0;
      text-align: left;
    }
  }

  @media ${devices.mobileMax} {
    width: 100%;
  }
`;

export const FlexContentSubSectionImage = styled.img`
  margin-left: 20px;
  margin-right: 40px;

  &.left {
    margin-left: 0;
  }
  &.right {
    margin-right: 0;
  }

  @media ${devices.tabletLMax} {
    max-width: 40%;
  }

  @media ${devices.mobileMax} {
    max-width: 90%;

    &.left {
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.right {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
  width: 100%;
  max-width: var(--page-max-width);

  .snowflakes-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      min-height: 50px;
    }
  }
`;

/* Grid */

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.bg-gray {
    background-color: ${(props) => props.theme.colors.bgGray};
  }

  .is-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    padding-top: var(--page-section-padding);
    width: 100%;

    .subtitle {
      margin: 16px 0;

      font-size: 24px;
      font-weight: 500;
      text-align: center;

      @media ${devices.mobileMax} {
        font-size: 16px;
      }
    }
    svg {
      width: 200px;

      @media ${devices.mobileMax} {
        width: 120px;
      }
    }
  }

  .is-container {
    padding-top: 56px;
    padding-top: var(--page-section-padding);
    padding-bottom: 56px;
    padding-bottom: var(--page-section-padding);
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s 0.1s, transform 0.6s ease;
    transition: opacity 0.6s 0.1s, transform 0.6s ease;
  }

  .is-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .is-download {
    margin-bottom: var(--page-section-padding);
    padding: 0 var(--page-gutter-padding);
  }
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 20px;
  padding: var(--page-gutter-padding);
  width: 100%;
  max-width: var(--page-max-width);
  box-sizing: border-box;

  .snowflakes-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      min-height: 50px;
    }
  }
`;

export const GridRowHeroContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const GridRowHeroImg = styled.img`
  &.background {
    position: absolute;
    display: block;
    top: 80px;

    @media ${devices.laptopSMax} {
      top: 100px;
    }
    @media ${devices.mobileMax} {
      top: 120px;
    }
  }
`;

export const GridRowImage = styled.img`
  grid-column: span 4;
  grid-column: span var(--page-grid-cols);
  object-fit: cover;
  width: 100%;
  z-index: 1;

  @media ${devices.tabletMin} {
    &.image-right {
      margin-top: auto;
      margin-bottom: auto;
      grid-column: span 4;
      order: 2;
    }
    &.image-left {
      margin-top: auto;
      margin-bottom: auto;
      grid-column: span 4;
    }
    &.image-bottom {
      margin-top: 24px;
    }
  }

  @media ${devices.laptopSMin} {
    &.image-right {
      grid-column: span 7;
    }
    &.image-left {
      grid-column: span 7;
      order: 1;
    }
  }
`;

export const GridRowVideoContainer = styled.div`
  display: flex;
  padding-top: 56px;
  padding-top: var(--page-section-padding);
  width: 100%;
  height: 56%;

  @media ${devices.mobileMax} {
    padding-top: 16px;
  }
`;

export const GridRowVideoPlayer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 8px);

  mux-player {
    margin: -4px 0;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --pip-button: none;
    --playback-rate-button: none;
  }
`;

export const GridRowDescription = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  grid-column: span 4;
  grid-column: span var(--page-grid-cols);

  &.image-right,
  &.image-left {
    margin-top: 20px;
  }
  &.image-bottom {
    margin-bottom: 20px;

    h1 {
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 24px;
  }

  a {
    color: ${(props) => props.theme.colors.textTertiary};
  }

  @media ${devices.tabletMin} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.image-right {
      margin-top: 0;
      grid-column: span 4;
      order: 1;
    }
    &.image-left {
      margin-top: 0;
      grid-column: span 4;
    }
    &.image-bottom {
      text-align: center;
    }
  }

  @media ${devices.laptopSMin} {
    &.image-right {
      margin-top: 0;
      grid-column: span 5;
    }
    &.image-left {
      grid-column: span 5;
      order: 2;
    }
    &.image-bottom {
      grid-column: span var(--page-grid-cols);
    }
  }
`;

export const GridRowDescriptionLink = styled(SecondaryLink)`
  margin-top: 8px;
  width: fit-content;
`;

export const GridDownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  width: auto;
  background-color: ${(props) => props.theme.colors.bgColor};
  border-radius: 28px;
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;

  &:hover {
    background-color: hsl(225, 39%, 56%);
    background-color: hsl(225, 39%, calc(var(--lightness-factor, 1) * 51%));
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    fill: ${(props) => props.theme.colors.textSecondary};
  }
`;
