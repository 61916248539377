import { toast } from "react-toastify";
import { UNAVAILABLE_LINKS } from "./globals";

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
}

export const matchInArray = (strSearch: string, expressions: string[]): boolean => {
  for (let exp of expressions) {
    if (strSearch.includes(exp)) {
      return true;
    }
  }
  return false;
}

export const createItems = (items: any[], type: string): any[] => {
  return items.map((item, index) => ({
    ...item,
    id: `${type}-${index}`,
  }));
}

export const handleExternallLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => {
  e.preventDefault();
  // These are currently disabled, but we'll keep this here for future use.
  if (UNAVAILABLE_LINKS.map(u => u.href).includes(href)) {
    const title = UNAVAILABLE_LINKS.find(u => u.href === href)?.title;
    toast.info(`${title}`, {
      position: 'bottom-center',
      autoClose: 2000,
    });
  } else {
    href.includes('http') ? window.open(href, '_blank') : window.open(href, '_self');
  }
};
