import styled from 'styled-components';

export const FooterItemContainer = styled.div`
  grid-column: span 2;
  margin-bottom: 40px;

  & a {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: ${(props) => props.theme.colors.textSecondary};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterItemTitle = styled.div`
  color: ${(props) => props.theme.colors.textTertiary};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; ;
`;
