import styled from 'styled-components';

export const FooterItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 20px;
  width: 100%;
`;

export const FooterItemsDivider = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 32px;
  width: 100%;
  height: 1px;
`;

export const FooterItemsSpacer = styled.div`
  display: initial;
  grid-column: span 1;
  grid-row-end: span 2;
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-column: span 3;
  grid-row-end: span 2;
  margin-bottom: 56px;
`;

export const FooterInfoHeader = styled.div`
  margin: 0;
  color: ${(props) => props.theme.colors.sloganPrimary};
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-size: 24px;
  font-weight: 700;
  line-height: 95%;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
  white-space: nowrap;

  & img {
    position: absolute;
    margin-left: 6px;
    height: 24px;
  }

  & span {
    color: ${(props) => props.theme.colors.sloganSecondary};
  }
`;

export const FooterInfoLocation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;

  & img {
    margin-right: 8px;
  }
`;

export const FooterInfoSocials = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  & a svg {
    height: 24px;
    fill: #fff;
  }

  & a:not(:first-child) svg {
    margin-left: 24px;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: span var(--page-grid-cols);
`;

export const FooterCopyright = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.textSecondary};
  opacity: 0.8;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;
