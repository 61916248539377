import { DropdownLink, DropdownSlot } from './header-dropdown.styles';

type HeaderDropdownProps = {
  title: string;
  idx: number;
  children: React.ReactNode;
  onMouseEnter: (i: number) => void;
  toggleDropdown: (i: number) => (e: any) => void;
};

export default function HeaderDropdown({ title, idx, children, onMouseEnter, toggleDropdown }: HeaderDropdownProps) {
  return (
    <DropdownLink
      className="is-dropdown"
      onMouseEnter={() => onMouseEnter(idx)}
      onFocus={() => onMouseEnter(idx)}
      onClick={toggleDropdown(idx)}
    >
      {title}
      <DropdownSlot>{children}</DropdownSlot>
    </DropdownLink>
  );
}
