import styled from 'styled-components';

export const DropdownLink = styled.div`
  position: relative;
  padding: 20px;
  user-select: none;
  z-index: 1000;
`;

export const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1500px;
`;
