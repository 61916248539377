import styled, { keyframes } from 'styled-components';

const getDropdownRootKeyFrame = ({
  animatingOut,
  direction,
}: {
  animatingOut: boolean;
  direction: 'left' | 'right' | undefined;
}) => {
  if (!animatingOut && direction) return null;

  return keyframes`
    from {
      transform: ${animatingOut ? 'rotateX(0)' : 'rotateX(-15deg)'};
      opacity: ${animatingOut ? 1 : 0};
    }
    to {
      transform: ${animatingOut ? 'rotateX(-15deg)' : 'rotateX(0)'};
      opacity: ${animatingOut ? 0 : 1};
    }
  `;
};

export const HeaderDropdownRoot = styled.div<{
  animatingOut: boolean;
  direction: 'left' | 'right' | undefined;
  duration: number;
}>`
  position: relative;
  top: -8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: 0 0;
  animation-name: ${getDropdownRootKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  animation-fill-mode: forwards;
  will-change: transform;
`;

export const HeaderDropdownArrow = styled.div`
  position: relative;
  top: 1px;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent ${(props) => props.theme.colors.bgPrimary};
  z-index: 1;
`;

export const HeaderDropdownBackgroundPrimary = styled.div`
  position: relative;
  overflow: hidden;
  transform-origin: 0 0;
  background-color: ${(props) => props.theme.colors.bgPrimary};
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.6);
  will-change: transform;
`;

export const HeaderDropdownBackgroundAlternate = styled.div<{
  duration: number;
}>`
  position: absolute;
  top: 0;
  left: -100%;
  background-color: ${(props) => props.theme.colors.gray100};
  width: 300%;
  height: 100%;
  transform-origin: 0 0;
  z-index: 0;
  transition: transform ${(props) => props.duration}ms;
`;

export const HeaderDropdownInverted = styled.div<{ absolute?: string }>`
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  will-change: transform;

  &:first-of-type {
    z-index: 1;
  }

  &:not(:first-of-type) {
    z-index: -1;
  }
`;

/* Fade contents */

const getFadeContainerKeyFrame = ({
  animatingOut,
  direction,
}: {
  animatingOut: boolean;
  direction: 'left' | 'right' | undefined;
}) => {
  if (!direction) return;

  return keyframes`
    to {
      transform: translateX(0);
      opacity: ${animatingOut ? 0 : 1};
    }
  `;
};

export const HeaderDropdownFadeContainer = styled.div<{
  animatingOut: boolean;
  direction: 'left' | 'right' | undefined;
  duration: number;
}>`
  animation-name: ${getFadeContainerKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  animation-fill-mode: forwards;
  opacity: ${(props) => (props.direction && !props.animatingOut ? 0 : 1)};
  top: 0;
  left: 0;
`;
